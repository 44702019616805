/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { Helmet } from 'react-helmet';
import { StitchedPunkMetadata } from 'web3/dtos';
import Layout from '../components/Layout';

export const query = graphql`
    {
        imgGallery: allImageSharp {
            edges {
                node {
                    gatsbyImageData(placeholder: BLURRED, quality: 75)
                    fluid {
                        originalName
                        originalImg
                    }
                }
            }
        }
    }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Gallery = ({ data }: { data: any }): JSX.Element => {
    const allPhotos: JSX.Element[] = [];

    // fetch all StitchedPunks from metadata file

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const stitchedPunkMetadata = require('../stitchedpunks-metadata') as StitchedPunkMetadata[];

    // sort by production date and show the newest on top
    const SortFunctionProductionDate = (a: StitchedPunkMetadata, b: StitchedPunkMetadata): number => {
        if (a.productionDate === undefined || b.productionDate === undefined) {
            return 0;
        }

        return a.productionDate.localeCompare(b.productionDate);
    };
    stitchedPunkMetadata.sort(SortFunctionProductionDate).reverse();

    const SortFunctionString = (a: string, b: string): number => a.localeCompare(b);

    stitchedPunkMetadata.forEach((stitchedPunk) => {
        const stitchedPunkPhotos = [stitchedPunk.imgIcon, ...stitchedPunk.imgGallery];

        stitchedPunkPhotos.sort(SortFunctionString).reverse();

        stitchedPunkPhotos.forEach((photo) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            data.imgGallery.edges.forEach((edge: any) => {
                if (edge.node.fluid.originalName === photo) {
                    allPhotos.push(
                        <div className="col-4" key={edge.node.fluid.originalName}>
                            <span className="image fit">
                                <a href={edge.node.fluid.originalImg}>
                                    <GatsbyImage
                                        image={edge.node.gatsbyImageData}
                                        alt="Photo of StitchedPunk"
                                        className="inverted-hover"
                                    />
                                </a>
                            </span>
                        </div>,
                    );
                }
            });
        });
    });

    return (
        <Layout>
            <Helmet
                title="StitchedPunks – Gallery"
                meta={[{ name: 'description', content: 'StitchedPunks – Gallery' }]}
            />

            <div id="gallery" className="alt">
                <section id="one">
                    <div className="inner">
                        <header className="major">
                            <h1>Gallery</h1>
                        </header>
                        <p>
                            Here you can see all photos of the StitchedPunks that were already created. The latest
                            photos will always be on top.
                        </p>
                        <p>
                            You can also visit the <Link to="/details">overview page</Link> to see the punks shown
                            below.
                        </p>
                        <div className="box alt">
                            <div className="grid-wrapper">{allPhotos}</div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    );
};

export default Gallery;
